import { configureStore } from '@reduxjs/toolkit';
import { isProd } from 'students/constants';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  devTools: isProd,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/open']
      }
    })
});

// if (import.meta.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     // eslint-disable-next-line
//     const newRootReducer = require('./rootReducer').default;
//     store.replaceReducer(newRootReducer);
//   });
// }

export type tAppState = ReturnType<typeof store.getState>;
export type tAppDispatch = typeof store.dispatch;

export default store;
