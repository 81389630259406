import 'core-js/stable';
import 'extensions/axiosHelper';
import setupSentry from 'extensions/setupSentry';
import 'proxy-polyfill/proxy.min';

import '../../extensions/webp_detect';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { init } from 'students/stores/_utils/common';
import consumer from '../../channels/application_cable/consumer';
import setupAppInterface from '../appInterface/setupAppInterface';
import rootStore from '../stores/rootStore';
import ErrorBoundary from '../views/shared/components/ErrorBoundary';

import 'students/styles/fonts/fonts.css';
import 'students/styles/styles.scss';
import { APP_DOM_ID } from '../constants';

setupSentry();
setupAppInterface();

async function setup() {
  rootStore.dispatch(init());

  try {
    const appModule = await import('../views/App');
    if (!appModule) {
      window.location.reload();
      return;
    }
    const App = appModule.default;

    const root = createRoot(document.getElementById(APP_DOM_ID)!);
    root.render(
      <ErrorBoundary>
        <Provider store={rootStore}>
          <App />
        </Provider>
      </ErrorBoundary>
    );

    consumer.subscriptions.create({ channel: 'Students::ApplicationChannel' });
  } catch (e) {
    window.location.reload();
  }
}

export default setup;
