import { initApi, userLocaleActions } from '@adeptlms/lingu-students-react-shared';
import { AxiosError, AxiosResponse } from 'axios';
import { DEFAULT_LOCALE } from 'i18n/config';
import { authActions } from '../auth';
import { tAppDispatch } from '../rootStore';
import { listenFederatedAuthMessage } from './federatedAuth';
import { postAuthCompleteMessage } from './helpers';

export function init(): (dispatch: tAppDispatch) => void {
  return (dispatch: tAppDispatch) => {
    initApi({
      onRejectResponse: apiErrorHandling(dispatch),
      onFulfilledResponse: apiResponseInterceptor(dispatch)
    });

    dispatch(authActions.checkAuth());
    dispatch(userLocaleActions.initLocale(DEFAULT_LOCALE));
    document.documentElement.setAttribute('lang', DEFAULT_LOCALE);

    listenFederatedAuthMessage(async () => {
      await dispatch(authActions.checkAuth());
      postAuthCompleteMessage();
    });
  };
}

function apiErrorHandling(dispatch: tAppDispatch) {
  return (error: AxiosError) => {
    const status = error.response?.status;
    if (status === 401) {
      dispatch(authActions.logOutStorageClear());
    }
    // TODO use server custom error codes like "error.response?.data.code === 10020"
    // else if (status === 403) {
    //   dispatch(showLessonLicenseModal());
    // }
    return Promise.reject(error);
  };
}

function apiResponseInterceptor(dispatch: tAppDispatch) {
  return (response: AxiosResponse<any>) => {
    const apiLocale = response.headers.locale || DEFAULT_LOCALE;
    dispatch(userLocaleActions.initLocale(apiLocale));
    document.documentElement.setAttribute('lang', apiLocale);

    return response;
  };
}
