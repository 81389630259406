export function setBrowserTimezone() {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const expires = new Date();
    expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
    document.cookie = `tz=${timezone};expires=${expires.toUTCString()};path=/;SameSite=Strict`;
  } catch (_e) {
    // do nothing
  }
}
