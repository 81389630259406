import { updateAuthTokens } from '@adeptlms/lingu-students-react-shared';

interface IFederatedAuthData {
  type: string;
  authenticity_token: string;
  jwt_token: string;
}
export function listenFederatedAuthMessage(cb?: () => void): void {
  function listener(e: MessageEvent<IFederatedAuthData>) {
    const {
      origin,
      data: { type, authenticity_token }
    } = e;

    if (origin !== window.location.origin) {
      return;
    }
    if (type !== 'oauth_callback') return;
    updateAuthTokens({ csrf: authenticity_token });
    cb?.();
  }
  window.addEventListener('message', listener);
}
